body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.App-link {
  color: #F0781E;
}

.MuiButton-textPrimary {
  background-color: #F0781E;
  color: #F0781E;
}

.MuiAppBar-colorSecondary {
  color: white;
  background-color: #F0781E;
}

.RaLogin-main-172 {
  height: 1px;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  justify-content: flex-start;
  background-image: radial-gradient(circle at 50% 14em, #F0781E 0%, #F0781E 60%, #F0781E 100%);
  background-repeat: no-repeat;
}